<template>
  <div class="page page__content-wrapper patterns">
    <div class="page__min-container">
      <div class="text__head">
        Фирменные паттерны
      </div>
      <div class="text__normal">
        В качестве дополнительного приема допускается использование паттернов, отсылающих к навигационной сетке или картографической разметке. Фирменный стиль включает ряд готовых примеров, однако ограничиваться ими необязательно.
      </div>

      <div
          v-for="(pattern, i) in patternList"
          :key="i"
          class="patterns__item"
      >
        <div class="patterns__item-image-wrap">
          <img
              :src="require(`../../../assets/img/patterns-page/${pattern.image}`)"
              :alt="pattern.image"
              class="patterns__item-image"
          >
        </div>

        <div class="patterns__item-download">
          <IconDownload
              v-for="(item, j) in pattern.downloadList"
              :key="j"
              :icon="item.icon"
              :url="item.url"
              class-name="patterns__item-download-icon"
          />
        </div>
      </div>

      <div class="patterns__btn">
        <ButtonDownload
          url="../download-file/05-Pattern/Steor-Pattern.zip"
          text-button="Скачать все паттерны"
          :icons="[{url: '', icon: 'figma.svg'}, {url: '', icon: 'ai.svg'}]"
        />
      </div>
    </div>

    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import IconDownload from "@/components/Elements/IconDownload/IconDownload";
import ButtonDownload from "@/components/Elements/ButtonDownload/ButtonDownload";
import BottomNavBlock from "../../../components/Elements/BottomNavBlock/BottomNavBlock"
export default {
  components: {BottomNavBlock, ButtonDownload, IconDownload},
  data() {
    return {
      bottomNav: {
        pre: {
          url: "/identity/picto",
          text: "Пиктограммы"
        },
        next: {
          url: "/identity/photo-style",
          text: "Фото и видео"
        }
      },
      patternList: [
        {
          image: 'Identity-Pattern1.svg',
          downloadList: [
            {
              url: "../download-file/05-Pattern/Steor-Pattern-1.ai",
              icon: 'icon-ai.svg'
            }
          ]
        },
        {
          image: 'Identity-Pattern2.svg',
          downloadList: [
            {
              url: "../download-file/05-Pattern/Steor-Pattern-2.ai",
              icon: 'icon-ai.svg'
            }
          ]
        },
        {
          image: 'Identity-Pattern3.svg',
          downloadList: [
            {
              url: "../download-file/05-Pattern/Steor-Pattern-3.ai",
              icon: 'icon-ai.svg'
            }
          ]
        },
        {
          image: 'Identity-Pattern4.svg',
          downloadList: [
            {
              url: "../download-file/05-Pattern/Steor-Pattern-4.ai",
              icon: 'icon-ai.svg'
            }
          ]
        },
        {
          image: 'Identity-Pattern5.svg',
          downloadList: [
            {
              url: "../download-file/05-Pattern/Steor-Pattern-5.ai",
              icon: 'icon-ai.svg'
            }
          ]
        },
      ]
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/var-style";

.patterns {
  &__button {
    //margin-top: 96px;
    width: 280px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding-right: 30px;
  }

  &.page {
    margin-bottom: 192px;
  }

  .page__min-container {
    max-width: 1016px;
  }

  .text__head {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 32px;
    letter-spacing: 0;
  }

  .text__normal {
    margin-bottom: 48px;
    max-width: 680px;
    letter-spacing: 0.3px;
    font-weight: 300;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-top: 32px;
  }

  &__btn {
    margin-top: 96px;
  }

  &__item-image-wrap {
    max-width: 832px;
    width: 100%;
    height: 416px;
  }

  &__item-image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  &__item-download {
    margin-left: 32px;
    display: flex;
  }

  &__item-download-icon {
    &:nth-child(n + 2) {
      margin-left: 24px;
    }
  }
}

.main-btn_download {
  padding-right: 31px !important;
}

@media (max-width: $mobile) {
  .patterns {
    &__item-download {
      display: none;
    }

    &__button {
      display: none;
    }

    &__item-image-wrap {
      height: 100%;
    }

    &__btn {
      display: none;
    }
  }
}
</style>